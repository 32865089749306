import React from 'react'

// Import global styles (browser normalization & app opinionation)
import { App, GlobalStyle } from './Root.styles'
// TODO: move normalize css to being imported as defered in the HTML
import '../../styles/normalize.sass'
// Styles and Media
import styles from '../../modules/Public/pages/LandingPage/LandingPage.module.sass'
// Import global svg spritesheet
import {
  SPRITE_GRAD,
  SVG_SPRITESHEET,
  SVG_PROMAZO,
} from '../../media/spritesheet/spritesheet.react'

const GRAD_LOGO = SPRITE_GRAD(
  'promazo_grad',
  'rgb(0,192,197)',
  'rgb(69,202,144)',
  1,
  1,
  45
)
/**
 * ProMazo Application - core of application logic
 */
export function Root() {
  return (
    <App>
      {/* Add spritesheet to root */}
      <SVG_SPRITESHEET />
      <GlobalStyle />
      {/* Map locations of the app to specific Modules */}
      <div className={styles.container}>
        {/* Background Img */}
        <SVG_PROMAZO
          fill={GRAD_LOGO.REF}
          grad={GRAD_LOGO.JSX}
          height="500px"
          className={styles.backgroundImg}
        />
        {/* Welcome Card */}
        <div className={styles.welcomeCard}>
          <SVG_PROMAZO
            fill={GRAD_LOGO.REF}
            grad={GRAD_LOGO.JSX}
            height="50px"
            width="32px"
          />
          <div className={styles.body}>
            <h2
              style={{
                marginBottom: '1.7em',
                marginTop: '1.2em',
              }}
            >
              Thank you!!
            </h2>
            <p>
              For the past few years, we have been working hard to build a
              platform to help people with their career development. We have
              learned a lot in the process, and are now working on a new version
              that will build on what has been an amaizing learning experience.
              We are excited to share it with you soon!
            </p>
            <p>
              If you have any questions, please don&#39;t hesitate to reach out
              to us at&nbsp;
              <a href="mailto:developers@promazo.com">developers@promazo.com</a>
              .
            </p>
            <p style={{ padding: '0px' }}>
              <b>Hope to see you soon on our new platform!</b>
              <br />
              <br />
              <i>The ProMazo Team</i>
              <br />
            </p>
          </div>
        </div>
      </div>
    </App>
  )
}

export default Root
