import React from 'react'
import ReactDOM from 'react-dom'
// eslint-disable-next-line
import { version } from '../package.json'
import { unregister } from './serviceWorker'

// Backend Integration / Telemetry
import { configure } from '@promazo/janus'
import * as Sentry from '@sentry/browser'

// Application Logic Root
import Root from './core/Root'

// Initialize Backend Resources
configure({
  Analytics: {
    disabled: true,
  },
})

// Initialize Sentry Error Logging
Sentry.init({
  dsn: 'https://6544e512fe704b558c9f6d71d0f06332@sentry.io/1854134',
  release: `promazo@${version}`,
})

/**
 * Render Core Application Logic on the #root DOM node.
 * This function establishes the global store, the error telemetry,
 * and the authentication engine. Once authenticated and initialized,
 * render the root of our application logic.
 */
ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister()
