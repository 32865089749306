import styled, { createGlobalStyle } from 'styled-components'

export const App = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-flow: row;
  justify-content: stretch;
  background-color: rgb(244, 247, 251);
  line-height: 1.5;
`

export const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`
